import React from "react";
import { Row, Col, Card } from "react-bootstrap";

// components
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";

//dummy data
import { records as data, expandableRecords } from "./data";

const columns = [
  {
    Header: "Id",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Plan Name",
    accessor: "name",
    sort: true,
  },
  {
    Header: "Acres",
    accessor: "number",
    sort: false,
  },
  {
    Header: "Lattitude",
    accessor: "lat",
    sort: true,
  },
  {
    Header: "Longitude",
    accessor: "long",
    sort: false,
  },
  {
    Header: "Battery(v)",
    accessor: "bat",
    sort: false,
  },
];

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

const Droneloc = () => {
  return (
<>
{/* <PageTitle
  breadCrumbItems={[
    { label: "Tables", path: "/features/tables/advanced" },
    {
      label: "Advanced Tables",
      path: "/features/tables/advanced",
      active: true,
    },
  ]}
  title={"Advanced Tables"}
/> */}

<Row>
  <Col>
    <Card>
      <Card.Body>
        {/* <h4 className="header-title">Pagination &amp; Sort</h4>
        <p className="text-muted font-14 mb-4">
          A simple example of table with pagination and column sorting
        </p> */}

        <Table
          columns={columns}
          data={data}
          pageSize={5}
          sizePerPageList={sizePerPageList}
          isSortable={true}
          pagination={true}
        />
      </Card.Body>
    </Card>
  </Col>
</Row>
</>
);
};

export default Droneloc;