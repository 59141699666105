import React from "react";
import { Row, Col } from "react-bootstrap";

// components
import PageTitle from "../../../components/PageTitle";
import SalesChart from "../../../components/SalesChart";
import IncomeChart from "../../../components/IncomeChart";
import UsersChart from "../../../components/UsersChart";

import Statistics from "./Statistics";
import RevenueChart from "./RevenueChart";
import TopSellingProducts from "./TopSellingProducts";

// dummy data
import { products } from "../Dashboard2/data";
import Droneloc from "../../tables/Droneloc";

const LiveDrone = () => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "LiveDrone", path: "/livedrone", active: true },
        ]}
        title={"Drone Live Location "}
      />
<Droneloc />
      {/* <Statistics /> */}

      <Row>
        {/* <Col lg={12}>
          <SalesChart />
        </Col> */}
        {/* <Col md={6} xl={4}>
          <IncomeChart />
        </Col> */}
        {/* <Col md={6} xl={4}>
          <UsersChart />
        </Col> */}
      </Row>

      <Row>
        <Col lg={12}>
          <RevenueChart />
        </Col>
        {/* <Col xl={6}>
          <TopSellingProducts products={products} />
        </Col> */}
      </Row>
    </>
  );
};

export default LiveDrone;
